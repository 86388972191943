.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker-popper {
  z-index: 2000 !important;
}

.leaflet-popup-tip-container {
  pointer-events: auto !important;
}

.main-table td,
.main-table th {
  border-bottom: thin solid var(--chakra-colors-gray-300);
  border-right: thin dashed var(--chakra-colors-gray-300);
}
.main-table td:last-child {
  border-right: 0;
}
.main-table td {
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
}
.main-table th {
  color: var(--chakra-colors-gray-50);
}

ckeditor {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.table-header {
  transition: background-color 0.1s ease;
}

.table-header:hover {
  background-color: #2c7a7b;
  cursor: pointer;
}
